(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/game-list/assets/javascripts/hooks/use-get-game-url.js') >= 0) return;  svs.modules.push('/components/marketplace/game-list/assets/javascripts/hooks/use-get-game-url.js');

'use strict';

const {
  useSelector
} = ReactRedux;
const {
  lbUtils
} = svs.components;
const {
  dressWithPreliminaryRow
} = svs.components.marketplace.dressWithPreliminaryRow;
const {
  PARAM_MARKETPLACE_GAME_ID
} = svs.components.sport.tipsenShared;
const {
  isTipsetProduct,
  productIds
} = svs.utils.products;
const isPortedProduct = productId => {
  if (isTipsetProduct(productId) || [productIds.BOMBEN].includes(productId)) {
    return true;
  }
  return false;
};
const useGetGameUrl = game => {
  const {
    activatedDrawId,
    drawNumber,
    productId
  } = game;
  const hasProductBeenPorted = isPortedProduct(productId);
  let searchString = lbUtils.getGameUrl({
    productId,
    drawNumber
  });
  if (hasProductBeenPorted) {
    searchString += "&".concat(PARAM_MARKETPLACE_GAME_ID, "=").concat(activatedDrawId);
  } else {
    searchString += "?".concat(PARAM_MARKETPLACE_GAME_ID, "=").concat(activatedDrawId, "&fromPlayTogether=true");
  }
  const gameUrl = useSelector(state => {
    if (activatedDrawId && drawNumber && productId) {
      return dressWithPreliminaryRow(state, activatedDrawId, productId, drawNumber, searchString);
    }
    return undefined;
  });
  return gameUrl;
};
setGlobal('svs.components.marketplace.gameList.hooks.useGetGameUrl', useGetGameUrl);

 })(window);