(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/game-list/assets/javascripts/favorite-badge.js') >= 0) return;  svs.modules.push('/components/marketplace/game-list/assets/javascripts/favorite-badge.js');

'use strict';

const {
  ReactIcon
} = svs.ui;
const FavoriteBadge = () => React.createElement("div", {
  className: "favorite-badge favorite-icon-container"
}, React.createElement(ReactIcon, {
  icon: "star",
  size: "100"
}));
setGlobal('svs.components.marketplace.gameList.FavoriteBadge', FavoriteBadge);

 })(window);