(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/game-list/assets/javascripts/helpers/apply-new-line.js') >= 0) return;  svs.modules.push('/components/marketplace/game-list/assets/javascripts/helpers/apply-new-line.js');

'use strict';

const applyNewLine = function () {
  let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let maxChars = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
  const textArr = text.split(' ');
  const retText = [];
  let lastLength = 0;
  textArr.forEach(t => {
    if (t.length + lastLength >= maxChars) {
      retText.push("".concat(t, " \n"));
      lastLength = 0;
    } else {
      retText.push("".concat(t, " "));

      lastLength = t.length + 1;
    }
  });
  return retText.join('').trim();
};
setGlobal('svs.components.marketplace.gameList.helpers.applyNewLine', applyNewLine);

 })(window);