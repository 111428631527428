(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/game-list/assets/javascripts/game-list.js') >= 0) return;  svs.modules.push('/components/marketplace/game-list/assets/javascripts/game-list.js');

'use strict';

const {
  useEffect,
  useRef,
  useState,
  useCallback
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useLocation,
  Link,
  useHistory
} = ReactRouterDOM;
const {
  ReactButton: Button
} = svs.ui;
const {
  Loader
} = svs.components.ReactLoader;
const {
  GroupList
} = svs.components.marketplace.groupList;
const {
  Game,
  GameStatus,
  GameSkeleton
} = svs.components.marketplace.gameList;
const {
  getTeamUrl,
  getRetailUrl
} = svs.components.marketplace.helpers;
const {
  selectGames,
  getIsLoading,
  getBrowseGames,
  getIsBrowseGamesLoading,
  getActiveGamesFetchedCount,
  getActiveGamesFetchableCount
} = svs.components.marketplaceData.selectors.games;
const {
  selectTeam
} = svs.components.marketplaceData.selectors.teams;
const {
  actions: actionsMockable
} = svs.components.marketplaceData.games;
const {
  roles
} = svs.components.marketplaceData.auth;
const {
  GAME_STATUS_OPEN
} = svs.components.marketplaceData.gamesConstants;
const {
  MAX_GAMES_TO_DISPLAY_ALL
} = svs.components.marketplaceData.gamesConstants;
const GameList = _ref => {
  let {
    className,
    fromTeamList,
    gameLinkSearch,
    groupId,
    hasBottomBorder,
    isListing,
    isTeamView,
    isRetail,
    maxGamesToDisplay: maxGamesToDisplayProp,
    hasNoFractionPayment = false,
    noFetch,
    isBrowseGames,
    offsetForLastClicked
  } = _ref;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const queryString = gameLinkSearch || location.search ? "?".concat(gameLinkSearch || location.search) : '';
  const games = useSelector(state => (isBrowseGames ? getBrowseGames(state, {
    groupId
  }) : selectGames(state, {
    groupId
  })) || []);
  const isLoading = useSelector(state => isBrowseGames ? getIsBrowseGamesLoading(state) : getIsLoading(state, {
    groupId
  }));
  const {
    name: groupName,
    role
  } = useSelector(state => selectTeam(state, {
    id: groupId
  }));
  const memberId = useSelector(state => state.customerId);
  const maxGamesToDisplay = maxGamesToDisplayProp === MAX_GAMES_TO_DISPLAY_ALL ? games.length : maxGamesToDisplayProp;
  const isLazyLoadingGamesList = isTeamView;
  const _checkIfHadLazyLoadingBeforeShowingGameDetails = () => isLazyLoadingGamesList && games.length > 20;
  const [isInfiniteScrollMode, setIsInfiniteScrollMode] = useState(_checkIfHadLazyLoadingBeforeShowingGameDetails());
  const [hasToFetchMore, setHasToFetchMore] = useState(false);
  const activeGamesFetched = useSelector(state => getActiveGamesFetchedCount(state, {
    groupId
  }));
  const activeGamesFetchable = useSelector(state => getActiveGamesFetchableCount(state, {
    groupId
  }));
  const loadDataRef = useRef(null);
  const lazyLoadingRef = useRef({
    intersectionObserver: null,
    observedElement: null,
    updatedData: {
      activeGamesFetched: 0,
      isLoading: false,
      hasMoreData: false
    }
  });
  lazyLoadingRef.current.updatedData = {
    activeGamesFetched,
    isLoading,
    hasMoreData: activeGamesFetched < activeGamesFetchable
  };
  const hasFetchMoreButton = isLazyLoadingGamesList && !isInfiniteScrollMode && lazyLoadingRef.current.updatedData.hasMoreData;
  useEffect(() => {
    if (isLazyLoadingGamesList) {
      const {
        current
      } = lazyLoadingRef;
      current.intersectionObserver = new IntersectionObserver(entries => {
        const hasLoadDataIntersect = entries.some(item => item.isIntersecting && item.target === loadDataRef.current);
        if (hasLoadDataIntersect && current.updatedData.hasMoreData && !current.updatedData.isLoading) {
          setHasToFetchMore(true);
          current.intersectionObserver.unobserve(loadDataRef.current);
          current.observedElement = null;
        }
      });
      return () => {
        current.intersectionObserver.disconnect();
        current.observedElement = null;
      };
    }
  }, [isLazyLoadingGamesList]);
  if (isInfiniteScrollMode && !hasToFetchMore) {
    if (loadDataRef.current && loadDataRef.current !== lazyLoadingRef.current.observedElement) {
      lazyLoadingRef.current.intersectionObserver.observe(loadDataRef.current);
      lazyLoadingRef.current.observedElement = loadDataRef.current;
    }
  }
  useEffect(() => {
    if (groupId && !noFetch) {
      dispatch(actionsMockable.fetchGamesAction({
        groupId,
        offset: 0
      }));
    }
  }, [dispatch, groupId, noFetch]);
  useEffect(() => {
    if (groupId && hasToFetchMore && !noFetch) {
      if (isLoading) {
        setHasToFetchMore(false);
      } else {
        dispatch(actionsMockable.fetchGamesAction({
          groupId,
          offset: lazyLoadingRef.current.updatedData.activeGamesFetched
        }));
      }
    }
  }, [hasToFetchMore, isLoading, noFetch, dispatch, groupId]);
  const onCreateTeam = useCallback(() => {
    history.push({
      pathname: getTeamUrl(groupId, groupName, 'skapa-spel'),
      state: {
        internalLink: true
      }
    });
  }, [groupId, groupName, history]);
  const renderOnEmpty = _ref2 => {
    let {
      groupId,
      groupName
    } = _ref2;
    const linkTo = isRetail ? getRetailUrl(groupId, groupName) : getTeamUrl(groupId, groupName);
    const title = isRetail ? 'Inga förberedda spel' : 'Laget har inga spel just nu';
    const isCaptainOrAdmin = role === roles.ROLES.CAPTAIN || role === roles.ROLES.ADMIN;
    return React.createElement(React.Fragment, null, React.createElement(Link, {
      className: "no-games-container-link",
      to: linkTo
    }, React.createElement("div", {
      className: "no-games-container-holder"
    }, React.createElement("div", {
      className: "no-games-container",
      "data-testid": "qa-no-games-container"
    }, React.createElement("div", {
      className: "game-list-info-text"
    }, title)))), isCaptainOrAdmin && React.createElement("div", {
      className: "no-games-container-btn",
      "data-testid": "qa-no-games-container-btn"
    }, React.createElement(Button, {
      block: true,
      onClick: () => onCreateTeam(),
      size: 100,
      transparent: true
    }, "Skapa ett nytt spel")));
  };
  const renderGameContainer = (game, gameIndex) => {
    var _ref3;
    const linkTo = isRetail ? getRetailUrl(groupId, groupName, 'spel', game.containerId, game.activatedDrawId) : getTeamUrl(groupId, groupName, 'spel', game.containerId, game.activatedDrawId);
    const fractionsCount = game.fractions && game.fractions[memberId] || 0;
    const productName = (_ref3 = game.productFamily || game.productName) === null || _ref3 === void 0 ? void 0 : _ref3.toLowerCase();

    const LinkComponent = _ref4 => {
      let {
        children
      } = _ref4;
      const classes = ['game-link', 'qa-navigate-game', hasBottomBorder && 'game-link-bottom-border'];
      const toProps = fromTeamList ? "".concat(linkTo).concat(queryString) : {
        pathname: linkTo,
        query: gameLinkSearch || location.search,
        state: {
          internalLink: true,
          hasBackButton: true
        }
      };
      return React.createElement(Link, {
        className: classes.filter(Boolean).join(' '),
        key: "game_".concat(gameIndex),
        onClick: () => {
          svs.components.Storage.browser.set('lastClickedGame', 'marketplace', {
            activatedDrawId: game.activatedDrawId,
            offset: offsetForLastClicked
          });
        },
        to: toProps
      }, children);
    };
    let showFewSharesLeft = false;
    if (game.soldShares !== game.maxFractions && game.status === GAME_STATUS_OPEN) {
      if (game.maxFractions < 5) {
        showFewSharesLeft = game.maxFractions - game.soldShares === 1;
      } else {
        showFewSharesLeft = game.soldShares / game.maxFractions >= 0.8;
      }
    }
    return React.createElement(React.Fragment, {
      key: game.activatedDrawId
    }, Boolean(game && game.activatedDrawId) && React.createElement(Game, {
      game: game,
      groupId: groupId,
      hasPayButton: !hasNoFractionPayment,
      isListing: isListing,
      isRetail: isRetail,
      LinkComponent: LinkComponent,
      productName: productName,
      shouldScrollTo: true,
      showFewSharesLeft: showFewSharesLeft
    }, React.createElement(GameStatus, {
      activatedDrawId: game.activatedDrawId,
      buyStop: game.buyStop,
      drawHasClosed: game.drawCloseTime <= Date.now(),
      fewSharesLeftMargin: showFewSharesLeft,
      fractionPrice: game.fractionPrice,
      fractionsCount: fractionsCount,
      gameStatus: game.status,
      groupId: groupId,
      hasBoughtFractions: fractionsCount > 0,
      hasPermissionToSubmit: game.isAllowedToSubmit,
      isFinished: game.isFinished,
      isListing: isListing,
      isRetail: isRetail,
      isSoldOut: game.isSoldOut,
      isSubmitted: game.isSubmitted,
      maxFractions: game.maxFractions,
      ownerName: game.ownerName
    })));
  };
  const classNames = ['game-list-container'];
  className && classNames.push(className);
  if (isLoading && !isInfiniteScrollMode) {
    return React.createElement("div", {
      className: classNames.join(' '),
      "data-testid": "qa-game-list-container-loading"
    }, React.createElement(GameSkeleton, null), React.createElement(GameSkeleton, null));
  }
  if (games.length === 0 && !isListing) {
    return renderOnEmpty({
      groupId,
      groupName
    });
  }
  return React.createElement("div", {
    className: classNames.join(' '),
    "data-testid": "qa-game-list-container"
  }, React.createElement(GroupList, {
    listData: games.slice(0, maxGamesToDisplay),
    renderItem: renderGameContainer
  }), Boolean(isLazyLoadingGamesList) && React.createElement("div", {
    ref: loadDataRef
  }), isLoading && React.createElement("div", {
    className: "loader-holder"
  }, React.createElement(Loader, {
    inverted: true
  })), Boolean(hasFetchMoreButton) && React.createElement("div", {
    className: "fetch-game-button-container"
  }, React.createElement(Button, {
    className: "qa-fetch-game-btn",
    onClick: () => setIsInfiniteScrollMode(true),
    transparent: true
  }, "VISA FLER SPEL")));
};
GameList.defaultProps = {
  className: '',
  hasBottomBorder: false,
  isRetail: false,
  noFetch: false
};
setGlobal('svs.components.marketplace.gameList.GameList', GameList);

 })(window);